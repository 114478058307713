.fixed.launch.button {
	padding: 5px;
	display: block;
	position: fixed;
	top: 2.5em;
	left: 0px;
	width: 55px;
	height: auto;
	white-space: nowrap;
	overflow: hidden;
	-webkit-transition: 0.3s width ease, 0.5s transform ease;
	-moz-transition: 0.3s width ease, 0.5s transform ease;
	-o-transition: 0.3s width ease, 0.5s transform ease;
	-ms-transition: 0.3s width ease, 0.5s transform ease;
	transition: 0.3s width ease, 0.5s transform ease;
	user-select: none;
	cursor: pointer;
}
